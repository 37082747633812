
import  React, { Fragment, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';




export default function Connexion(){
 
  const marg = {
    marginTop:'-50px',
  };


  
  let navigate =useNavigate();
  const [user, setUser]=useState({
    email: '',
    password:''

  })
  const handleChange = (e) => {

    setUser(({...user, [e.target.name]: e.target.value}));

}



const submitForm=(e) => {
e.preventDefault();
const sendData = {
    email:user.email,
    password:user.password

}
console.log(sendData);
axios.post('https://lapostecolis.laposte-ga.com/login1/',sendData).then((result)=>{
 if (result.data.Status === '200'){
window.localStorage.setItem('email', result.data.email);
window.localStorage.setItem('id_user', result.data.id_user);
window.localStorage.setItem('pass', result.data.pass);


    navigate('/Accueil');
   
 } else {
    alert ('Utilisateur inexistant !');
 }
}

)

}


    return(

      <Fragment>
<nav className="fix_topscroll logo_on_fixed  topbar navigation">
  <div className="nav-wrapper container">
    <span id="logo-container" className=" brand-logo " >La poste</span>    
      
      
   
  </div>
</nav>

<div className="content-area">




  
<div className="login-bg access-login"></div>


<div style={marg} className="container login-area">
  <div className="section">


    <h3 className="bot-20 center white-text">Authentification</h3>
    
<form onSubmit={submitForm}>
    <div className="row">
<div className="spacer"></div>
<div className="spacer"></div>
      <div className="input-field col s10 offset-s1">
        <input name='email' value={user.email} onChange={handleChange} id="email311" type="email" className="validate" />
        <label for="email311">Email</label>
      </div>

    </div>

    <div className="row">

      <div className="input-field col s10 offset-s1">
        <input value={user.password} name='password' onChange={handleChange} id="pass311" type="password" className="validate" />
        <label for="pass311">Mot de passe</label>
      </div>
    
    </div>


    <div className="row">

<div className="col s10 offset-s1 text-white">
<Link to='/Reset'><font color="orange">Mot de passe oublié ?</font></Link>
</div>

</div>

        
<div className="row center">
  <button className="waves-effect waves-light btn-large bg-primary">Connexion</button>
  <div className="spacer"></div>


    <div className="spacer"></div>
  

</div>
</form>


    


  </div>
</div>







</div>



</Fragment>

        );

}