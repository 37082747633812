
import  React, { Fragment, useEffect } from 'react';
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

export default function Accueil(){
 
    const back1 = {
        backgroundImage: 'url("./assets/assets/images/full-1.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '600px',
      };

  


      const [colis1, setColis1] = useState([]);
      const [colis2, setColis2] = useState([]);
      const [colis4, setColis4] = useState([]);
      const [colis5, setColis5] = useState([]);
      const [colis, setColis] = useState([]);
      const [colis3, setColis3] = useState([]);
      const [colis6, setColis6] = useState([]);
    const [email, setAuth] = useState('');
    const { id_user } = useParams();
    const navigate = useNavigate();
   


  
 
    useEffect(() => {
        // Écoute de l'événement de mise à jour des colis
        async function fetchData() {
          try {
            const colisData = await getColis(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis(colisData); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }
    
        async function fetchData1() {
          try {
            const colisData1 = await getColis1(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis1(colisData1); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }
    
        async function fetchData2() {
          try {
            const colisData2 = await getColis2(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis2(colisData2); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }
    
        async function fetchData3() {
          try {
            const colisData3 = await getColis3(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis3(colisData3); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }
    
        async function fetchData4() {
          try {
            const colisData4 = await getColis4(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis4(colisData4); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }
    
        async function fetchData5() {
          try {
            const colisData5 = await getColis5(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis5(colisData5); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }

        async function fetchData6() {
          try {
            const colisData6 = await getColis6(); // Use await to retrieve the returned data
            const email = localStorage.getItem('email');
            if (email === null) {
              navigate('/Connexion');
            }
            setAuth(email);
            setColis6(colisData6); // Set the retrieved data in the state
          } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
          }
        }
        

        fetchData(); // Exécute fetchData une fois immédiatement
  
       
       
        fetchData1(); // Exécute fetchData une fois immédiatement
  
        fetchData2(); 
        fetchData3(); 
        fetchData4(); 
        fetchData5(); 
        fetchData6(); 
        const intervalId = setInterval(fetchData, 1000); // Exécute fetchData toutes les 3 secondes
        const intervalId1 = setInterval(fetchData1, 1000); // Exécute fetchData toutes les 3 secondes
        const intervalId2 = setInterval(fetchData2, 1000); // Exécute fetchData toutes les 3 secondes
        const intervalId3 = setInterval(fetchData3, 1000); // Exécute fetchData toutes les 3 secondes
        const intervalId4 = setInterval(fetchData4, 1000); // Exécute fetchData toutes les 3 secondes
        const intervalId5 = setInterval(fetchData5, 1000); // Exécute fetchData toutes les 3 secondes
        const intervalId6 = setInterval(fetchData6, 1000); // Exécute fetchData toutes les 3 secondes
      
        return () => {
          clearInterval(intervalId1); // Nettoie l'intervalle lors du démontage du composant
          clearInterval(intervalId);
          clearInterval(intervalId2);
          clearInterval(intervalId3);
          clearInterval(intervalId4);
          clearInterval(intervalId5);
          clearInterval(intervalId6);
        };


      }, []);
  
    const logOut = () => {
      localStorage.removeItem('email');
      localStorage.clear();
      navigate('/Connexion');
    };
   
    
    const getColis = async () => {
      try {
        const response = await axios.get(`https://lapostecolis.laposte-ga.com/dist3/${localStorage.getItem('id_user')}`);
        console.log(response.data);
        return response.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };

    const getColis1 = async () => {
      try {
        const response1 = await axios.get(`https://lapostecolis.laposte-ga.com/dist4/${localStorage.getItem('id_user')}`);
        console.log(response1.data);
        return response1.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };

    const getColis2 = async () => {
      try {
        const response2 = await axios.get(`https://lapostecolis.laposte-ga.com/dist5/${localStorage.getItem('id_user')}`);
        console.log(response2.data);
        return response2.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };


    const getColis3 = async () => {
      try {
        const response3 = await axios.get(`https://lapostecolis.laposte-ga.com/dist6/${localStorage.getItem('id_user')}`);
        console.log(response3.data);
        return response3.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };

    
    const getColis4 = async () => {
      try {
        const response4 = await axios.get(`https://lapostecolis.laposte-ga.com/dist6/${localStorage.getItem('id_user')}`);
        console.log(response4.data);
        return response4.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };

        
    const getColis5 = async () => {
      try {
        const response5 = await axios.get(`https://lapostecolis.laposte-ga.com/dist6/${localStorage.getItem('id_user')}`);
        console.log(response5.data);
        return response5.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };

    const getColis6 = async () => {
      try {
        const response6 = await axios.get(`https://lapostecolis.laposte-ga.com/dist9/${localStorage.getItem('id_user')}`);
        console.log(response6.data);
        return response6.data; // Return the response data
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        throw error; // Rethrow the error to handle it in the fetchData function
      }
    };
    const totalColis = colis.map((coli) => coli.nb).concat(colis1.map((coli1) => coli1.nb)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const livColis = colis3.map((coli3) => coli3.nb).concat(colis2.map((coli2) => coli2.nb)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);


    const courColis = colis4.map((coli4) => coli4.nb).concat(colis5.map((coli5) => coli5.nb)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return(

      <Fragment>




<nav className="fix_topscroll logo_on_fixed  topbar navigation">
  <div className="nav-wrapper container">
    <span id="logo-container" className=" brand-logo " >La Poste</span>    
      
      <a href="#"><img style={{height:'40px', marginTop:'5px'}} className="circle" src="./assets/assets/images/DELINAISON4.png" alt="menu user" /></a>
    
    
   

    
  </div>
</nav>
<ul id="slide-nav" className="sidenav sidemenu">
  <li className="user-wrap">
    <div className="user-view row">
     
            <div className="col s3 imgarea">
        <a href="#user" className="status available"><img className="circle" src="./assets/assets/images/menu-user.jpg" alt="menu user" /></a>
      </div>
            <div className="col s9 infoarea">
       
      </div>
    </div>
    
        


  </li>


  <li className="menulinks">
    <ul className="collapsible">
     

</ul>
</li>


     
    <li className="menu-users">
        




  </li>

  </ul>



  <ul id="slide-settings" className="sidenav sidesettings ">
   
  </ul>
<div className="menu-close"><i className="mdi mdi-close"></i></div>

<div className="content-area">

  <div className="pagehead-bg   primary-bg" >
    </div> 






  <div className="spacer"></div>
<div className="spacer"></div>
<div className="spacer"></div>
<div className="spacer"></div>






<div className="container">
    <div className="section pb0 pt0">

    <div className="row mb0">
                <div class="col s12 pad-0">    

<div className="row">
   
    <div className="col s6 m4 l3">
    <div className="icon-block overlay z-depth-1 primary-icon">
      <div className="icon-area center primary-text"><i class="mdi mdi-car-pickup
 z-depth-1"></i></div>
      <h6 className="title-area center">Expéditions</h6>
      {colis.map((coli) => (
        <p className="text-area center" key={coli.id_colis}>{coli.nb}</p>
     
      ))}
    </div>
  </div>

    <div className="col s6 m4 l3">
    <div className="icon-block overlay z-depth-1 primary-icon">
      <div className="icon-area center primary-text"><i class="mdi mdi-subdirectory-arrow-right
 z-depth-1"></i></div>
      <h6 className="title-area center">Réceptions</h6>
      {colis1.map((coli1) => (
        <p className="text-area center">{coli1.nb}</p>
     
      ))}
    </div>
  </div>

  <div className="col s6 m4 l3">
    <div className="icon-block overlay z-depth-1 primary-icon">
      <div className="icon-area center primary-text"><i class="mdi mdi-home z-depth-1"></i></div>
      <h6 className="title-area center">En agence</h6>
       
      {colis6.map((coli6) => (
        <p className="text-area center" key={coli6.id_colis}>{coli6.nb}</p>
     
      ))}
    
    
    </div>
  </div>

  <div className="col s6 m4 l3">
    <div className="icon-block overlay z-depth-1 primary-icon">
      <div className="icon-area center primary-text"><i class="mdi mdi-update z-depth-1"></i></div>
      <h6 className="title-area center">En cours</h6>
      <p className="text-area center">{totalColis - livColis}</p>
    </div>
  </div>

   


  <div className="col s12 m12 l12">
    <div className="icon-block overlay z-depth-1 primary-icon">
      <div className="icon-area center primary-text"><i class="mdi mdi-gift z-depth-1"></i></div>
      <h6 className="title-area center">Colis livrés</h6>
       
        <p className="text-area center">{livColis}</p>
     
    
    
    
    </div>
  </div>


 

  

  </div>

    </div>
    </div>
    
    </div>
  </div>






  <div className="row home-mode" style={{background:'orange'}}>
  <div className="spacer"></div>
  <div className="spacer"></div>
  <h5 className="center bot-0 sec-tit primary-text pad-15">La Poste du GABON</h5>
 
  <p className="center-align black-text pad-30">Tel: (+241) 774442290</p>
 
  <p className="center-align black-text pad-30">BP: 20000</p>

  <div className="center">

  
  </div>
  <div className="spacer"></div>
  <div className="spacer"></div>
</div>


<div className="spacer"></div>








<div className="backtotop">
  <a className="btn-floating btn primary-bg">
    <i className="mdi mdi-chevron-up"></i>
  </a>
</div>



</div>

<div className="footer-menu circular">
  <ul>
      <li >
      <Link to='/Expedition'>    <i className="mdi mdi-car-pickup"></i>
      <span>Expédit.</span>
      </Link>      </li>
        <li >
        <Link to='/Reception'>     <i className="mdi mdi-subdirectory-arrow-right
"></i>
      <span>Récept.</span>
      </Link>  </li>
        <li>
      <a className="active" href="#" >      <i className="mdi mdi-home-outline"></i>
      <span>Accueil</span>
      </a>    </li>
        <li>
        <Link to='/Compte'>        <i className="mdi mdi-account"></i>
      <span>Compte.</span>
      </Link>    </li>
        <li ><Link to='/Connexion' onClick={logOut} >       <i className="mdi mdi-power"></i>
      <span>Décon.</span>
      </Link>  </li>
      
  </ul>
</div>






</Fragment>

        );

}