
import  React, { Fragment, useEffect,useRef } from 'react';
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function Expedition() {
    const [colis, setColis] = useState([]);
    const [email, setAuth] = useState('');
    const { id_user } = useParams();
    const navigate = useNavigate();
    

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getColis();
        const email = localStorage.getItem('email');
        if (email === null) {
          navigate('/Connexion');
        }
        setAuth(email);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };
  
    fetchData(); // Exécute fetchData une fois immédiatement
  
    const intervalId = setInterval(fetchData, 1000); // Exécute fetchData toutes les 3 secondes
  
    return () => {
      clearInterval(intervalId); // Nettoie l'intervalle lors du démontage du composant
    };
  }, []);
  
    const logOut = () => {
      localStorage.removeItem('email');
      localStorage.clear();
      navigate('/Connexion');
    };
  
    const getColis = async () => {
      try {
        const response = await axios.get(`https://lapostecolis.laposte-ga.com/dist1/${localStorage.getItem('id_user')}`);
        setColis(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };
    


    return(

      <Fragment>




<nav className="fix_topscroll logo_on_fixed  topbar navigation">
  <div className="nav-wrapper container">
    <span id="logo-container" className=" brand-logo " >La Poste </span>    
      
      <a href="#"><img style={{height:'40px', marginTop:'5px'}} className="circle" src="./assets/assets/images/DELINAISON4.png" alt="menu user" /></a>
    
    
   

    
  </div>
</nav>
<ul id="slide-nav" className="sidenav sidemenu">
  <li className="user-wrap">
    <div className="user-view row">
     
            <div className="col s3 imgarea">
        <a href="#user" className="status available"><img className="circle" src="./assets/assets/images/menu-user.jpg" alt="menu user" /></a>
      </div>
            <div className="col s9 infoarea">
       
      </div>
    </div>
    
        


  </li>


  <li className="menulinks">
    <ul className="collapsible">
     

</ul>
</li>


     
    <li className="menu-users">
        




  </li>

  </ul>



  <ul id="slide-settings" className="sidenav sidesettings ">
   
  </ul>
<div className="menu-close"><i className="mdi mdi-close"></i></div>

<div className="content-area">

  <div className="pagehead-bg   primary-bg" >
    </div> 






  <div className="spacer"></div>
<div className="spacer"></div>
<div className="spacer"></div>
<div className="spacer"></div>






<div className="container over transparent pr0 pl0">



<div className="section pt0 pb0">
            

            <div className="row ">
          
                        <div className="col s12 pad-0">  
                        <h5 className="bot-20 sec-tit  text-white" style={{color:'white',}}>Colis Expédiés</h5>
                          <div className="icon-block overlay z-depth-1 primary-icon">
      <div className="icon-area center primary-text"><i class="mdi mdi-car-pickup"></i></div>
      <h6 className="title-area center">Expéditions</h6>
   
        <p className="text-area center">{colis.length}</p>
     
  
    </div>

                        <div style={{overflowX:'auto',overflowY:'auto',maxHeight:'350px'}}>
                          <table className='colored primary'>
                <thead>
                  <tr>
                      <th>Matri.</th>
                      <th>Type</th>
                     
                      <th>Locali.</th>
                      <th>Nature</th>
                      <th>Mont.</th>
                      <th>Desti.</th>
                
                      
                  </tr>
                </thead>
        
                <tbody>

        {colis.map((coli, key) =>
                                   <tr key={key}>

                                   <td style={{fontSize:'12px',color:'black'}}>P-00{coli.id_colis}</td>
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.type_colis}</td>
                                   <td style={{fontSize:'12px',color:'black'}}>
                                   {coli.localisation !='Livré' ? (
                                    <span className='badge badge-warning' style={{color:'#E4A11B'}}>{coli.localisation}</span>
                                   ) : (
                                    <span className='badge' style={{color:'#14A44D'}}>{coli.localisation}</span>

                                   )}

                                    </td>
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.nom_colis}</td>
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.montant_colis}</td>
                                   <td style={{fontSize:'12px',color:'black'}}>{coli.destination_colis}</td>
                                  
                                 
                                 </tr>
                   )}
                </tbody>
              </table>

              </div>

            </div>
            </div>
            
          
            
       
            
          
      
                    <div className="spacer"></div>
                    
            
        
            </div>




  </div>









  <div className="row home-mode" style={{background:'orange'}}>
  <div className="spacer"></div>
  <div className="spacer"></div>
  <h5 className="center bot-0 sec-tit primary-text pad-15">La Poste du GABON</h5>
 
  <p className="center-align black-text pad-30">Tel: (+241) 774442290</p>
 
  <p className="center-align black-text pad-30">BP: 20000</p>

  <div className="center">

  
  </div>
  <div className="spacer"></div>
  <div className="spacer"></div>
</div>



<div className="spacer"></div>








<div className="backtotop">
  <a className="btn-floating btn primary-bg">
    <i className="mdi mdi-chevron-up"></i>
  </a>
</div>



</div>

<div className="footer-menu circular">
  <ul>
      <li >
      <a className="active" href="#" >      <i className="mdi mdi-car-pickup"></i>
      <span>Expédit.</span>
      </a>    </li>
<li>
      <Link to='/Reception'>     <i className="mdi mdi-subdirectory-arrow-right
"></i>
      <span>Récept.</span>
      </Link> 
      </li>
        <li>
        <Link to='/Accueil'>     <i className="mdi mdi-home-outline"></i>
      <span>Accueil</span>
      </Link>     </li>
      <li >
        <Link to='/Compte'>        <i className="mdi mdi-account"></i>
      <span>Compte.</span>
      </Link>    </li>
        <li >
      <Link to='/Connexion' onClick={logOut} >      <i className="mdi mdi-power"></i>
      <span>Décon.</span>
      </Link>    </li>
      
  </ul>
</div>






</Fragment>

        );

}